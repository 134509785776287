import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { formatDateTime } from 'helpers/dateHelpers';
import {
    BorderCrossingIcon,
    CheckInIcon,
    CheckOutIcon,
    MeetingIcon,
    RentalCarIcon,
    TicketIcon,
    LocationIcon
} from 'ui/icons';
import getLocationLink from 'shared/deep-link/getLocationLink';

import styles from './ActivityCardHalf.styles';

const typeMap = {
    ACCOMMODATION_CHECK_IN: {
        text: 'Check In',
        icon: CheckInIcon,
        showTime: false
    },
    ACCOMMODATION_CHECK_OUT: {
        text: 'Check Out',
        icon: CheckOutIcon,
        showTime: false
    },
    MEETING: {
        text: 'Meeting',
        icon: MeetingIcon,
        showTime: true
    },
    BORDER_CROSSING: {
        text: 'Border Crossing',
        icon: BorderCrossingIcon,
        showTime: false
    },
    CAR_PICK_UP: {
        text: 'Car Pick Up',
        icon: RentalCarIcon,
        showTime: false
    },
    CAR_DROP_OFF: {
        text: 'Car Drop Off',
        icon: RentalCarIcon,
        showTime: false
    }
};

const ActivityCardHalf = props => {
    const {
        classes,
        dateTime,
        description,
        name,
        type,
        location = {},
        ticket = {}
    } = props;
    if (!typeMap[type]) {
        return null;
    }

    const Icon = typeMap[type].icon;
    const showTime = typeMap[type].showTime;

    let PDF;
    const hasTicket = !!ticket && !!ticket.id;

    if (hasTicket) {
        PDF = require(`static/files/${ticket.path}`);
    }

    const links = [];

    if (hasTicket) {
        links.push(
            <div key="links_ticketLink" className={classes.link}>
                <a href={PDF} target="_blank" rel="noopener noreferrer">
                    <TicketIcon size={20} />
                    <div>Ticket</div>
                </a>
            </div>
        );
    }

    if (!!location && !!location.latitude) {
        links.push(
            <div key="links_locationLink" className={classes.link}>
                <a
                    href={getLocationLink({
                        location: `${location.latitude},${location.longitude}`,
                        title: name
                    })}
                >
                    <LocationIcon size={20} />
                    <div>Location</div>
                </a>
            </div>
        );
    }

    return (
        <div className={classes.cardWrapperHalf}>
            <div className={classes.title}>
                <div>{typeMap[type].text}</div>
            </div>
            <div className={classes.iconWrapper}>
                <Icon size={48} />
            </div>
            {!!showTime && (
                <div className={classes.time}>
                    {!!dateTime && formatDateTime(dateTime, 'HH:mm')}
                </div>
            )}
            {!!name && <div className={classes.smallText}>{name}</div>}
            {!!description && (
                <div className={classes.infoText}>{description}</div>
            )}
            {!!links.length && (
                <div className={classes.linksWrapper}>{links}</div>
            )}
        </div>
    );
};
ActivityCardHalf.displayName = 'ActivityCardHalf';
ActivityCardHalf.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
        'ACCOMMODATION_CHECK_IN',
        'ACCOMMODATION_CHECK_OUT',
        'MEETING',
        'BORDER_CROSSING',
        'CAR_PICK_UP',
        'CAR_DROP_OFF'
    ]),
    name: PropTypes.string,
    dateTime: PropTypes.string,
    description: PropTypes.string,
    location: PropTypes.shape({
        latitude: PropTypes.string,
        longitude: PropTypes.string
    }),
    ticket: PropTypes.shape({
        id: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        description: PropTypes.string
    })
};

export default withStyles(styles)(ActivityCardHalf);
